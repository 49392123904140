export default function InputWrapper({
  icon, actionIcon, errorMessage, children, action = () => {},
}) {
  return (
    <div className={`input-wrapper relative w-full bg-white ${errorMessage ? 'with-errors' : ''}`}>
      {icon}
      <div className="relative">
        {children}
      </div>
      {actionIcon && (
        <div className="group hover:bg-gray-400 cursor-pointer items-center justify-center bg-gray-200 z-20 absolute h-full w-[50px] right-0 top-0 flex" onClick={action}>{actionIcon}</div>
      )}
      {errorMessage && (
        <label className="error-label absolute px-2 py-1 text-white">{errorMessage}</label>
      )}
    </div>
  );
}
